label {
  display: block;
  margin-bottom: 5px;
}

.item {

}

.option_image {
  width: 20px;
  margin-right: 5px;
}

th {
  text-align: left;
}

table {
  //width: 100%;
}

.summary {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  div {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      &:last-child {
        font-size: 1.6rem;
      }
    }
  }
}

.create_order_table {
  white-space: nowrap;
  overflow-x: auto;

  //  hide scrollbar
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

}

.item_box {
  width: 100%;
  cursor: pointer;
  border-radius: 3px;
  padding: 5px;
  border: 2px solid var(--color-white);

  img {
    width: 100%;
    height: 100px;
    object-fit: contain;
    border-radius: 3px;
  }

  span {
    display: block;
    text-align: center !important;
    color: var(--color-primary-db);
    font-weight: bold;
  }
}

.item_active {
  border: 2px solid var(--color-primary-db);
}

.qty_control {
  display: flex;
  gap: 5px;
}
.ant-drawer-body {
    @media(max-width: 576px) {
        padding: 5px !important;
    }
}

.ant-drawer-body-wrapper {
   @media(max-width: 576px) {
        margin: 5px !important;
    }
}

.text-lt {
  text-decoration: line-through;
}
:root {
  --color-main-dark: #141414;
  --color-dark: #596f7b;
  --color-main-dark-light: #76798c;
  --color-white: #fff;
  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f8f9fb;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;
  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;
  /*Dashboard Color*/
  --color-grey-green: #f4f6f3;
  --color-primary-db: #0383b9;
  --color-secondary-db: #d5eb87;
  --color-black: #171717;
  --color-ok: #0383b9;
  --color-red: #ff4d4f;
  --color-gold: #fbb120;
  --color-awesome: #0383b9; }

* {
  margin: 0;
  padding: 0; }

*,
*::after,
a::before {
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%; }

body {
  font-weight: 400;
  line-height: 1.6;
  color: #677b87;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--color-grey-green) !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important; }

input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none; }

button:focus {
  outline: 0 !important; }

Link {
  text-decoration: none !important; }

:link {
  text-decoration: none; }

.closed {
  display: none !important; }

/* width */
::-webkit-scrollbar {
  width: 8px; }

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-grey-green); }

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-primary-db); }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; }

a {
  text-decoration: none; }

th {
  white-space: nowrap !important; }

ul {
  padding-left: 0 !important; }

.custom-ant-table .ant-table-content {
  overflow-x: auto; }
  .custom-ant-table .ant-table-content::-webkit-scrollbar {
    height: 4px !important; }

.admin-header {
  background-color: var(--color-white);
  height: 80px;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-grey-green);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100; }

.admin-search {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  flex: 0 0 50%; }
  @media (max-width: 991px) {
    .admin-search {
      display: none; } }
  .admin-search input {
    border: none;
    background-color: var(--color-grey-green);
    padding: 1rem 3rem;
    width: 70%;
    border-radius: 100px; }
    .admin-search input::placeholder {
      font-size: 1.4rem; }
  .admin-search button {
    border: none;
    margin-left: -4rem;
    background-color: transparent; }
  .admin-search svg {
    color: var(--color-grey-dark-2);
    height: 2rem;
    width: 2rem; }

/*>> Admin Sidebar Sass*/
.admin-sidebar {
  position: fixed;
  top: 0;
  left: -270px;
  margin-top: 80px;
  height: calc(100% - 80px);
  z-index: 200;
  display: flex;
  flex-direction: column;
  width: 270px;
  background-color: var(--color-white); }

.admin-sidebar--alt {
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 80px;
  height: calc(100% - 80px);
  z-index: 200;
  display: flex;
  flex-direction: column;
  width: 270px;
  background-color: var(--color-white);
  overflow: auto; }

.short-menu {
  position: fixed;
  left: 0;
  bottom: 0;
  margin-top: 80px;
  background-color: var(--color-white);
  height: calc(100% - 80px);
  width: 60px;
  padding: 4rem 0; }
  .short-menu ul {
    width: 100% !important;
    display: flex;
    flex-direction: column; }
  .short-menu__item {
    padding: 1rem 0;
    width: 100% !important;
    list-style: none;
    margin: 1rem 0; }
    .short-menu__item svg {
      margin-left: 50%;
      transform: translateX(-50%);
      color: var(--color-grey-dark-2);
      height: 2rem;
      width: 2rem; }

.sm-active {
  background-color: var(--color-grey-light-2); }
  .sm-active svg {
    color: var(--color-primary-db); }

.admin-sidebar__header {
  height: 80px;
  border-bottom: 1px solid var(--color-grey-light-3);
  padding-right: 4rem;
  display: flex;
  align-items: center; }

.admin-sidebar__user-box {
  padding: 2rem 2rem; }
  .admin-sidebar__user-box__greeting {
    color: var(--color-main-dark);
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 0.3rem; }
  .admin-sidebar__user-box__msg {
    font-size: 1.2rem;
    color: var(--color-grey-dark-2);
    display: flex;
    align-items: center;
    margin-bottom: 2rem; }
    .admin-sidebar__user-box__msg span {
      margin: 0 0.5rem;
      color: var(--color-primary-db); }
    .admin-sidebar__user-box__msg svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 1.5rem; }

.admin-sidebar__menu {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 80%;
  padding-left: 1.5rem; }
  .admin-sidebar__menu__title {
    color: var(--color-grey-dark-2);
    font-weight: 600;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 1.5rem; }
  .admin-sidebar__menu__item {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 109%;
    padding: 1rem 2rem;
    margin-bottom: 1.2rem;
    color: var(--color-grey-dark-2); }
    .admin-sidebar__menu__item--icon {
      height: 1.6rem;
      width: 1.6rem;
      margin-right: 25px !important; }
    .admin-sidebar__menu__item--title {
      font-size: 1.5rem;
      font-weight: 500; }
  .admin-sidebar__menu .active {
    background-color: var(--color-grey-green);
    color: var(--color-primary) !important;
    border-radius: 100px; }
    .admin-sidebar__menu .active .admin-sidebar__menu__item--title {
      color: var(--color-main-dark); }
    .admin-sidebar__menu .active .admin-sidebar__menu__item--icon {
      color: var(--color-primary-db) !important; }

.admin-main-content--alt {
  padding: 4rem;
  background-color: var(--color-grey-green);
  min-height: 100%;
  margin-left: 270px;
  margin-top: 80px; }
  @media (max-width: 768px) {
    .admin-main-content--alt {
      margin-left: 0;
      padding: 1rem; } }

.admin-main-content {
  padding: 2rem;
  background-color: var(--color-grey-green);
  min-height: 100%;
  margin-left: 65px;
  margin-top: 80px; }
  @media (max-width: 768px) {
    .admin-main-content {
      margin-left: 0;
      padding: 2rem; } }

.dashboard-full {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 3rem; }
  @media (max-width: 991px) {
    .dashboard-full {
      flex-direction: column;
      gap: 2rem; } }

.dashboard-half {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column; }
  @media (max-width: 991px) {
    .dashboard-half {
      width: 100%; } }

.dashboard-card {
  background-color: var(--color-white);
  border-radius: 5px;
  padding: 4rem 2rem; }

/*<<Admin Dashboard Layout*/
.header-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 45rem;
  margin-left: auto;
  gap: 1rem; }
  @media (max-width: 577px) {
    .header-wrapper button {
      font-size: 12px !important; } }
  @media (max-width: 768px) {
    .header-wrapper {
      justify-content: flex-end;
      gap: 1rem; } }

.admin-svg {
  height: 2.2rem;
  width: 2.2rem;
  color: var(--color-grey-dark-2); }

.admin-avatar {
  height: 4rem;
  border-radius: 100px;
  cursor: pointer; }

.admin-table {
  background-color: var(--color-white);
  padding: 3rem; }

.general-svg {
  height: 15px;
  width: 15px; }

.btext-area {
  border: none;
  background-color: var(--color-grey);
  padding: 0.5rem;
  border-radius: 5px; }

.logo {
  font-size: 2rem;
  color: var(--color-black); }

/*>>Login Page Sass*/
.login-container {
  background-color: var(--color-grey-light-2);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .login-container form {
    max-width: 400px; }
    @media (max-width: 576px) {
      .login-container form {
        max-width: 300px; } }

@media (max-width: 768px) {
  .hide_tablet {
    display: none !important; } }

@media (max-width: 576px) {
  .hide_mobile {
    display: none !important; } }

@media (max-width: 768px) {
  .show_tablet {
    display: block !important; } }

@media (min-width: 768px) {
  .show_tablet {
    display: none !important; } }

@media (max-width: 576px) {
  .show_mobile {
    display: block !important; } }

@media (min-width: 576px) {
  .show_mobile {
    display: none !important; } }

.blabel {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 1rem;
  display: inline-block;
  margin-top: 2rem;
  color: var(--color-grey-dark-2); }

.bselect {
  width: 100%;
  background-color: var(--color-grey-green);
  border: none;
  padding: 1rem;
  font-size: 1.3rem;
  font-weight: 500; }

.binput {
  width: 100%;
  border: none;
  padding: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
  border: 2px solid var(--color-white);
  background-color: var(--color-grey-green); }
  .binput:focus {
    border: 2px solid var(--color-primary-db); }

.cinput {
  width: 100% !important;
  border: none;
  padding: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
  border: 2px solid var(--color-white); }
  .cinput:focus {
    border: 2px solid var(--color-primary-db); }

.file-input-box {
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  border: 2px dotted var(--color-primary-db);
  position: relative; }

.file-input-svg {
  height: 3rem;
  width: 3rem;
  color: var(--color-grey-dark-2);
  fill: var(--color-grey-dark-2); }

.binput-file {
  background-color: var(--color-white);
  visibility: hidden; }

.file-input-label {
  font-size: 1.3rem;
  color: var(--color-primary-db);
  font-weight: 500; }

.file-input-master-wrapper {
  background-color: var(--color-white);
  padding: 2rem;
  display: flex;
  flex-direction: column; }
  .file-input-master-wrapper__gallery {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; }
    .file-input-master-wrapper__gallery--image {
      margin-top: 2rem;
      width: 10%;
      flex: 0 0 18%;
      object-fit: contain;
      border-radius: 5px; }

.bbutton {
  border: none;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  font-weight: 500;
  border-radius: 3px;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Uni Neue", sans-serif; }
  .bbutton svg {
    margin-right: 1rem; }

.bbutton-primary {
  background-color: var(--color-primary-db); }

.bbutton-loading {
  background-color: #7a7a76;
  cursor: not-allowed;
  height: 42px; }

.white {
  color: var(--color-white) !important; }

/*>>Dashboard Page Header*/
.dashboard-page-header {
  display: flex;
  background-color: var(--color-white);
  padding: 3rem 2rem;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 4rem;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .dashboard-page-header {
      flex-direction: column;
      align-items: flex-start;
      padding: 1.5rem !important;
      margin-bottom: 1.5rem; } }
  .dashboard-page-header__left {
    flex: 0 0 20%;
    display: flex;
    align-items: center; }
    .dashboard-page-header__left--title {
      font-size: 2rem;
      font-weight: 500;
      color: var(--color-main-dark);
      margin-bottom: 0.2rem; }
    .dashboard-page-header__left svg {
      color: var(--color-primary-db);
      height: 2rem;
      width: 2rem;
      margin-left: 1.5rem;
      cursor: pointer;
      animation: all 4s; }
      .dashboard-page-header__left svg:active {
        animation: spin 4s linear infinite; }
  .dashboard-page-header__right {
    flex: 0 0 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem; }
    @media (max-width: 991px) {
      .dashboard-page-header__right {
        width: 100%;
        margin-top: 1rem;
        flex-wrap: wrap; } }
    .dashboard-page-header__right form {
      flex: 32%;
      min-width: 100px; }
      @media (max-width: 991px) {
        .dashboard-page-header__right form {
          flex: 1; } }
    .dashboard-page-header__right select {
      flex: 20%;
      min-width: 100px; }
      @media (max-width: 991px) {
        .dashboard-page-header__right select {
          flex: 1; } }
    .dashboard-page-header__right button {
      flex: 32%;
      min-width: 100px; }
      @media (max-width: 991px) {
        .dashboard-page-header__right button {
          flex: 1; } }

.dashboard-page-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between; }

/*<<Dashboard Page Header*/
/*>> Dashboard Table And Data*/
.dashboard-table-wrapper {
  background-color: var(--color-white);
  padding: 2rem;
  font-size: 1.3rem;
  border-right: 1px solid #fff;
  border-radius: 5px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */ }
  .dashboard-table-wrapper::-webkit-scrollbar {
    display: none; }

th {
  color: var(--color-primary-db); }

.span-primary {
  padding: 0.5rem 1rem;
  background-color: var(--color-secondary-db);
  color: var(--color-primary-db);
  text-transform: capitalize;
  border-radius: 5px; }

.productImageTable {
  border-radius: 5px; }

/*<< Dashboard Table And Data*/
.ctext-area {
  width: 100%;
  border: none;
  height: 100px;
  padding: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
  border: 2px solid var(--color-white);
  background-color: var(--color-grey-green); }
  .ctext-area:focus {
    border: 2px solid var(--color-primary-db); }

td {
  color: var(--color-dark);
  padding: 1rem !important; }

th {
  padding: 1rem 1.5rem !important; }

/*>>Pop Drawer Sass*/
.pop-drawer {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.377);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; }
  .pop-drawer__body {
    position: fixed;
    top: 0;
    right: 0 !important;
    height: 100vh;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    transition: all 0.3s; }
    @media (max-width: 991px) {
      .pop-drawer__body {
        width: 60% !important; } }
    .pop-drawer__body__wrapper {
      padding: 5rem 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      /* Track */
      /* Handle */
      /* Handle on hover */ }
      .pop-drawer__body__wrapper::-webkit-scrollbar {
        width: 0; }
      .pop-drawer__body__wrapper::-webkit-scrollbar-track {
        background: var(--color-grey-green); }
      .pop-drawer__body__wrapper::-webkit-scrollbar-thumb {
        background: var(--color-primary-db); }
      .pop-drawer__body__wrapper::-webkit-scrollbar-thumb:hover {
        background: #555; }
      .pop-drawer__body__wrapper h2 {
        color: var(--color-primary-db);
        font-size: 2rem;
        margin-bottom: 2rem; }
  .pop-drawer__footer {
    margin-top: auto;
    background-color: var(--color-white);
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 960px) {
      .pop-drawer__footer {
        flex-direction: column;
        gap: 1rem; } }

.pop-drawer__body-closed {
  transition: all 0.3s;
  right: -1000rem !important;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-grey-green); }

.tag {
  background-color: var(--color-primary-db);
  padding: 0.1rem 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  width: fit-content;
  border-radius: 100px;
  display: flex;
  align-items: center;
  color: var(--color-white); }
  .tag svg {
    margin-left: 0.5rem;
    cursor: pointer; }

.tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  white-space: nowrap; }

.breadcrumb-custom {
  font-size: 1.3rem;
  margin-bottom: 2rem;
  color: var(--color-grey-dark-3); }
  .breadcrumb-custom span:last-child {
    color: var(--color-primary-db); }

.s-file-upload::-webkit-file-upload-button {
  color: white;
  display: inline-block;
  background: var(--color-primary-db);
  border: none;
  padding: 7px 10px;
  font-weight: 700;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
  margin-right: 2rem;
  font-size: 10pt; }

.s-file-upload {
  margin: 2rem 0;
  margin-left: 10%; }

.color-button-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px; }

.color-button {
  border: 1px solid var(--color-white);
  padding: 1rem 2rem;
  color: white;
  border-radius: 5px;
  color: var(--color-primary-db); }

.color-button-active {
  border: 2px solid var(--color-primary-db);
  padding: 1rem 2rem;
  color: var(--color-primary-db);
  margin-right: 1rem;
  border-radius: 5px; }

.status {
  display: flex;
  align-items: center; }

.status-span {
  height: 12px;
  width: 12px;
  min-width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.cbutton {
  border: none;
  border-radius: 50px;
  padding: 0.5rem 1.5rem;
  background-color: #83c4f54f;
  display: flex;
  align-items: center; }
  .cbutton svg {
    width: 2rem;
    height: 2rem;
    color: var(--color-primary-db); }

table {
  margin-bottom: 0rem !important; }

.order-pricing-box {
  background-color: var(--color-white);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  width: 100%; }
  .order-pricing-box span {
    margin-bottom: 0.5rem; }
  .order-pricing-box__special-span {
    font-weight: bold;
    color: var(--color-primary-db);
    font-size: 1.5rem; }

.pagination {
  margin: 6rem 0 2rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .pagination-span {
    display: inline-block;
    margin-top: 2rem;
    font-weight: 600;
    color: var(--color-primary-db); }

.badge-info {
  margin-bottom: 1rem; }

.loader {
  display: flex;
  align-items: center;
  justify-content: center; }

.taka {
  font-family: "Hind Siliguri", sans-serif; }

.db-card {
  border-radius: 5px;
  padding: 2rem;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column; }

.info {
  margin-bottom: 1rem;
  color: var(--color-dark); }
  .info__1 {
    font-weight: 600; }

.title-primary {
  color: var(--color-primary-db);
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600; }
  .title-primary svg {
    margin-right: 1rem; }

.drawer-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 300;
  margin: 1rem 0;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center; }
  @media (max-width: 576px) {
    .drawer-title {
      font-size: 1.6rem; } }
  .drawer-title::before {
    content: "";
    background-color: var(--color-primary-db);
    height: 1px;
    width: 3%;
    top: 50%;
    margin-right: 2rem; }
  .drawer-title::after {
    content: "";
    background-color: var(--color-primary-db);
    height: 1px;
    width: 3%;
    top: 50%;
    margin-left: 2rem; }

.no-resource {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem; }
  .no-resource img {
    width: 200px; }

.am {
  display: flex;
  align-items: center; }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.metrics-card {
  background-color: var(--color-white);
  width: 100%;
  height: 100%;
  padding: 4rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }
  .metrics-card title {
    display: block;
    color: var(--color-primary-db);
    font-size: 2rem;
    font-weight: 600; }

.image-div-with-delete-button {
  flex: 0 0 18%;
  position: relative;
  cursor: pointer; }
  .image-div-with-delete-button svg {
    position: absolute;
    top: 15%;
    right: 10%;
    color: var(--color-red); }

form {
  width: 100%; }

.ant-message-custom-content {
  display: flex !important;
  align-items: center !important; }

.ant-modal-content {
  max-height: 80vh;
  background-color: var(--color-grey-light-2) !important; }

.ant-message-notice-content {
  background-color: #3f3f3f !important;
  color: white !important; }

.ant-drawer-body {
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .ant-drawer-body::-webkit-scrollbar {
    width: 0px; }
  .ant-drawer-body::-webkit-scrollbar-track {
    background: white; }
  .ant-drawer-body::-webkit-scrollbar-thumb {
    background: var(--color-primary-db); }
  .ant-drawer-body::-webkit-scrollbar-thumb:hover {
    background: var(--color-grey-dark-2); }

.ant-drawer-content {
  height: 100% !important;
  overflow-y: scroll !important; }

.ant-drawer-body {
  background-color: var(--color-grey-light-2); }

.ant-drawer-body-wrapper {
  margin: 2rem;
  background-color: #fff;
  padding: 2rem;
  border-radius: 5px; }
  .ant-drawer-body-wrapper title {
    color: var(--color-primary-db); }

.ant-table-cell:has(.custom-table-cell) {
  padding: 0 !important;
  border: none !important; }

.custom-item-table .ant-table-container {
  border: none !important; }

.custom-item-table table {
  border: none !important; }

.invoice {
  min-width: 58mm;
  max-width: 58mm;
  padding: 3mm;
  font-size: 10px !important;
  color: black !important;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 20mm;
  }

  span {
    text-align: center;
    margin-top: 2mm;
  }
}

.info {
  margin-top: 5mm;
}

.table {
  max-width: 50mm;
  margin-top: 2mm;
  color: black !important;

  th {
    text-align: left;
    padding: 1mm !important;
    color: black !important;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }

  td {
    padding: 1mm !important;
    color: black !important;
    border-bottom: 1px solid black;
  }
}

.hr {
  border-top: 1px solid black;
  content: "";
  width: 100%;
  margin: 2mm 0;
}

.summary {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 2mm;
  margin-bottom: 2mm;

  div {
    display: flex;
    gap: 2mm;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  em {
    font-style: normal;
  }
}
.label {
  width: 101.6mm;
  height: 152.4mm;
  //background-color: beige;
  //padding: 2mm;
  font-weight: bold !important;

  span {
    font-size: 15px;
  }
}

.body {
  border: 4px solid black;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  //padding: 2mm;
}

.badge {
  display: block;
  //background-color: black;
  //background: black;
  color: black;
  font-weight: 800;
  width: fit-content;
  height: fit-content;
  margin: 0;
  padding: 0;
}

.hr {
  content: "";
  border-top: 4px solid black;
  height: 4px;
}

.right_b {
  border-right: 4px solid black;
  height: 100%;
}

.table {
  margin-top: 5px;
  font-size: 10px;
  font-weight: bold;
  color: black !important;
  tr {
    border-bottom: 1px solid black;
  }
  th {
    padding: 2px !important;
    color: black;
  }
  td {
    padding: 2px !important;
    color: black !important;
  }
}

.payment_status {
  position: absolute;
  left: 10%;
  top: 20%;
  text-transform: uppercase;
  font-size: 30px !important;
  font-weight: bold;
}
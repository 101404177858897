.icon_wrap {
    background-color: #f7f8fa;
    padding: 1rem;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
}

.title {
    @media(max-width: 577px) {
        //font-size: 12px;
    }
}